<div class="register-product">
  <ng-container *ngIf="successOnConfirm">
    <div class="box-sucess">
      <div class="flex justify-content-between">
        <div
          class="font-medium text-3xl text-900 mb-3 title cursor-default color-green"
        >
          Peça publicada com sucesso!
        </div>
        <div></div>
      </div>
      <div class="mb-3">
        <p>
          Código da peça: <strong class="code">{{ code }}</strong>
        </p>
      </div>
    </div>
    <p-menu
      [model]="successOnConfimMenuItems"
      class="success-on-create-menu flex"
    ></p-menu>
  </ng-container>

  <ng-container *ngIf="!successOnConfirm">
    <div class="flex justify-content-between">
      <div
        class="font-medium text-3xl text-blue-800 mb-3 title cursor-default"
        *ngIf="status === 1 && !id"
      >
        Cadastrar peça
      </div>
      <div
        class="font-medium text-3xl text-900 mb-3 title cursor-default"
        *ngIf="id || (status !== 1 && !code)"
      >
        Editando peça<span *ngIf="status !== 1 && code">: #{{ code }}</span>
      </div>
      <div>
        <button
          pButton
          type="button"
          label="Cancelar"
          icon="fa-solid fa-times"
          class="inline-flex p-button-danger"
          (click)="cancel()"
          [disabled]="isLoading"
        ></button>
        <button
          pButton
          type="button"
          label="Salvar rascunho"
          icon="fa-solid fa-triangle-exclamation"
          class="ml-2 inline-flex p-button-warning"
          (click)="confirm(true)"
          [disabled]="isLoading"
          *ngIf="id === null"
        ></button>
        <button
          pButton
          type="button"
          label="Salvar"
          icon="fa-solid fa-check"
          class="ml-2 inline-flex"
          (click)="confirm()"
          [disabled]="isLoading"
        ></button>
      </div>
    </div>

    <div>
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px' }"
        *ngIf="isLoading"
      ></p-progressBar>
      <div class="surface-card">
        <div class="flex justify-content-end">
          <p-tag
            value="Disponível"
            class="available"
            *ngIf="status === 2"
          ></p-tag>
          <p-tag
            value="Indisponível"
            class="unavailable"
            *ngIf="status === 3"
          ></p-tag>
          <p-tag
            value="Rascunho em distribuição"
            class="draft-distribution"
            *ngIf="status === 4"
          ></p-tag>
        </div>

        <p-tabView [(activeIndex)]="activeTabIndex" class="btn-nav">
          <!-- 1. TAB GENERAL (INFORMATION): Start -->
          <p-tabPanel [disabled]="isLoading">
            <ng-template pTemplate="header">
              <!-- <i class="fa-solid fa-book"></i>&nbsp; -->
              <div class="btn-custom border-round-left">1. Peça</div>
            </ng-template>
            <div class="form-main">
              <div class="text-xl pb-4">Geral</div>
              <div class="p-fluid grid formgrid">
                <div class="field col-12 md:col-5">
                  <label for="name" class="required">Nome</label>
                  <input
                    id="name"
                    #nameInput
                    type="text"
                    pInputText
                    maxlength="150"
                    [(ngModel)]="name"
                    [disabled]="isLoading"
                    (change)="onChangeValidate($event)"
                    (keydown)="onChangeValidate($event)"
                    (keyup)="onChangeValidate($event)"
                  />
                  <app-characters-remaining
                    [field]="nameInput"
                    [value]="name"
                  ></app-characters-remaining>
                </div>

                <div class="field col-12 md:col-3">
                  <label for="kind" class="required">Tipo</label>
                  <p-dropdown
                    id="kind"
                    [options]="kindOptions"
                    [(ngModel)]="kind"
                    optionLabel="label"
                    [filter]="true"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Selecione um tipo"
                    [disabled]="isLoading || id !== null"
                  >
                    <ng-template pTemplate="selectedItem">
                      <div class="flex mb-1" *ngIf="kind">
                        <!-- <i [class]="'fa-solid ' + kind.icon + ' mr-2'"></i> -->
                        <div>{{ kind.label }}</div>
                      </div>
                    </ng-template>
                    <ng-template let-kind pTemplate="item">
                      <div class="flex">
                        <!-- <i [class]="'fa-solid ' + kind.icon + ' mr-2'"></i> -->
                        <div>{{ kind.label }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>

                <div class="field col-12 md:col-4">
                  <label for="category" class="required mb-3">Categoria</label>
                  <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                      <p-radioButton
                        name="category"
                        value="C"
                        [(ngModel)]="category"
                        inputId="category-campaign"
                      ></p-radioButton>
                      <label for="category-campaign" class="cursor-pointer ml-2"
                        >Campanha</label
                      >
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton
                        name="category"
                        value="P"
                        [(ngModel)]="category"
                        inputId="category-constant"
                      ></p-radioButton>
                      <label for="category-constant" class="cursor-pointer ml-2"
                        >Permanente</label
                      >
                    </div>
                  </div>
                  <!-- <p-selectButton id="subject" [options]="subjectOptions" [(ngModel)]="subject" optionLabel="label"
                    optionValue="value" [disabled]="isLoading"></p-selectButton> -->
                  <!-- <small>O assunto que é abordado com esta peça.</small> -->
                </div>

                <!--                <div class="field col-12 md:col-4">-->
                <!--                  <label for="category" class="required">Categoria</label>-->
                <!--                  <div class="flex h-2rem align-items-center gap-4">-->
                <!--                    <div>-->
                <!--                      <input id="category-campaign" [disabled]="isLoading" type="radio" value="C"-->
                <!--                             [(ngModel)]="category"/>-->
                <!--                      <label for="category-campaign" class="text-lg ml-3">Campanha</label>-->
                <!--                    </div>-->
                <!--                    <div>-->
                <!--                      <input id="category-constant" [disabled]="isLoading" type="radio" value="P"-->
                <!--                             [(ngModel)]="category"/>-->
                <!--                      <label for="category-constant" class="text-lg ml-3">Permanente</label>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; <p-selectButton-->
                <!--                    id="category"-->
                <!--                    [options]="categoryOptions"-->
                <!--                    [(ngModel)]="category"-->
                <!--                    optionLabel="label"-->
                <!--                    optionValue="value"-->
                <!--                    [disabled]="isLoading"-->
                <!--                  ></p-selectButton> &ndash;&gt;-->
                <!--                </div>-->

                <div class="field col-12 md:col-12" *ngIf="isKindKit()">
                  <label for="kits" class="required"
                    >Peça(s) que compõe o kit</label
                  >
                  <p-autoComplete
                    id="kits"
                    [(ngModel)]="kits"
                    [suggestions]="filteredKits"
                    (completeMethod)="filterKits($event)"
                    field="label"
                    [multiple]="true"
                    [disabled]="isLoading"
                  >
                    <ng-template let-kind pTemplate="item">
                      <div class="flex align-items-center">
                        <i [class]="'fa-solid ' + kind.icon + ' mr-2'"></i>
                        <span>{{ kind.label }}</span>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                  <!-- <small>Digite o início do nome e selecione uma opção.</small> -->
                </div>

                <div class="field col-12 md:col-6">
                  <div class="flex justify-content-between mb-2">
                    <label for="answerable" class="required"
                      >Área demandante</label
                    >
                    <small
                      ><a href="#" (click)="showAddAnswerableDialog()"
                        >Adicionar</a
                      ></small
                    >
                  </div>
                  <p-autoComplete
                    id="answerable"
                    [(ngModel)]="answerable"
                    [suggestions]="answerableOptions"
                    (completeMethod)="filterAnswerable($event)"
                    field="label"
                    [disabled]="isLoading"
                  ></p-autoComplete>
                </div>

                <div class="field col-12 md:col-6">
                  <div class="flex justify-content-between mb-2">
                    <label for="communication" class="required"
                      >Ação de comunicação</label
                    >
                    <small
                      ><a href="#" (click)="showAddCommunicationDialog()"
                        >Adicionar</a
                      ></small
                    >
                  </div>
                  <p-autoComplete
                    id="communication"
                    [(ngModel)]="communication"
                    [suggestions]="communicationOptions"
                    (completeMethod)="filterCommunication($event)"
                    field="label"
                    [disabled]="isLoading"
                  >
                    <ng-template let-item pTemplate="item">
                      <div class="flex">
                        <img
                          [src]="item.thumb"
                          width="16"
                          class="mr-2"
                          [pTooltip]="
                            '<img src=\'' +
                            item.thumb +
                            '\' width=\'200\' class=\'pr-4\'>'
                          "
                          tooltipPosition="right"
                          [escape]="false"
                        />
                        <div>{{ item.label }}</div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                  <!-- <small>Digite o início do nome e selecione uma opção.</small> -->
                </div>

                <div class="field col-12 md:col-6">
                  <div class="flex justify-content-between mb-2">
                    <label for="supplier" class="required">Fornecedor</label>
                    <small
                      ><a href="#" (click)="showAddSupplierDialog()"
                        >Adicionar</a
                      ></small
                    >
                  </div>
                  <p-dropdown
                    id="supplier"
                    [options]="supplierOptions"
                    [(ngModel)]="supplier"
                    optionLabel="label"
                    [filter]="true"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Selecione um fornecedor"
                    [disabled]="isLoading"
                  ></p-dropdown>
                </div>

                <div class="field col-12 md:col-6">
                  <div class="flex justify-content-between mb-2">
                    <label for="publicity-agency" class="required"
                      >Criador (agência/house que criou a peça)</label
                    >
                    <small
                      ><a href="#" (click)="showAddPublicityAgencyDialog()"
                        >Adicionar</a
                      ></small
                    >
                  </div>
                  <p-dropdown
                    id="publicity-agency"
                    [options]="publicityAgencyOptions"
                    [(ngModel)]="publicityAgency"
                    optionLabel="label"
                    [filter]="true"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Selecione um criador"
                    [disabled]="isLoading"
                  ></p-dropdown>
                </div>

                <div class="mb-3 col-12 md:col-12">
                  <div
                    class="field col-12 md:col-3 pl-0 pr-0"
                    *ngIf="expireAtIndeterminate === false"
                    #expireAtArea
                  >
                    <!-- *ngIf="canShowExpireAtArea()" -->
                    <label for="expire-at" class="required">Validade</label>
                    <p-calendar
                      id="expire-at"
                      [(ngModel)]="expireAt"
                      [showButtonBar]="true"
                      [showIcon]="true"
                      [minDate]="minDate"
                      [readonlyInput]="false"
                      [disabled]="isLoading || expireAtIndeterminate"
                      [appendTo]="expireAtArea"
                    ></p-calendar>
                  </div>

                  <div
                    class="field flex-1 mb-5"
                    [class.error]="expireAtIndeterminate === null"
                  >
                    <div class="flex gap-4 mb-3">
                      <div>
                        <input
                          id="expire-at-indeterminate"
                          [disabled]="isLoading"
                          type="checkbox"
                          [value]="false"
                          (ngModelChange)="changeExpireAtIndeterminate($event)"
                          [(ngModel)]="expireAtIndeterminate"
                        />
                        <label
                          for="expire-at-indeterminate"
                          class="text-lg ml-3"
                        >
                          Validade Indeterminada</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field col-12 md:col-12">
                  <label for="target" class="required">Público-alvo</label>
                  <p-selectButton
                    id="target"
                    [options]="targetOptions"
                    [(ngModel)]="target"
                    optionLabel="label"
                    optionValue="value"
                    [disabled]="isLoading"
                    (onChange)="onChangeTarget($event)"
                  ></p-selectButton>

                  <div
                    *ngIf="targetItemsOptions.length > 0"
                    class="flex flex-wrap gap-2 mt-3"
                  >
                    <div
                      *ngFor="let item of targetItemsOptions"
                      class="field-checkbox"
                    >
                      <input
                        type="checkbox"
                        [id]="'target-item-' + item.value"
                        [name]="'target-item-' + item.value"
                        [value]="item.value"
                        [checked]="item.checked"
                        (change)="onChangeTargetItem(item)"
                        [disabled]="isLoading"
                      />

                      <label
                        [for]="'target-item-' + item.value"
                        class="cursor-pointer"
                        >{{ item.label }}</label
                      >
                      <br />
                    </div>
                  </div>

                  <div
                    *ngIf="targetItemsSubsectionOptions.length > 0"
                    class="ml-3 pl-3 pt-1 border-round target-subsection"
                  >
                    <div class="flex flex-wrap gap-2 mt-3">
                      <div
                        *ngFor="let item of targetItemsSubsectionOptions"
                        class="field-checkbox"
                      >
                        <input
                          type="checkbox"
                          [id]="'target-item-' + item.value"
                          [name]="'target-item-' + item.value"
                          [value]="item.value"
                          [checked]="item.checked"
                          (change)="onChangeTargetSubItem($event, item)"
                          [disabled]="isLoading"
                        />
                        <label
                          [for]="'target-item-' + item.value"
                          class="cursor-pointer"
                          >{{ item.label }}</label
                        ><br />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="field col-12 md:col-12">
                  <label for="target" class="required">Público-alvo</label>
                  <p-selectButton
                    id="target"
                    [options]="targetOptions"
                    [(ngModel)]="target"
                    optionLabel="label"
                    optionValue="value"
                    [disabled]="isLoading"
                    (onChange)="onChangeTarget($event)"
                  ></p-selectButton>
                  <div *ngIf="!!targetItemsOptions && targetItemsOptions.length > 0" class="flex flex-wrap gap-2 mt-3">
                    <div *ngFor="let item of targetItemsOptions" class="field-checkbox">
                      <input
                        type="checkbox"
                        [id]="'target-item-' + item.value"
                        [name]="'target-item-' + item.value"
                        [value]="item.value"
                        [checked]="targetItems.includes(item.value)"
                        (change)="onChangeTargetItem($event, item)"
                        [disabled]="isLoading"
                      />
                      <label [for]="'target-item-' + item.value" class="cursor-pointer">{{ item.label }}</label>
                      <br/>
                    </div>
                  </div>

                  <div *ngIf="targetItemsSubsectionOptions.length > 0"
                       class="ml-3 pl-3 pt-1 border-round target-subsection">
                    <div class="flex flex-wrap gap-2 mt-3">
                      <div *ngFor="let item of targetItemsSubsectionOptions" class="field-checkbox">
                        <input
                          type="checkbox"
                          [id]="'target-item-' + item.value"
                          [name]="'target-item-' + item.value"
                          [value]="item.value"
                          [checked]="targetItems.includes(item.value)"
                          (change)="onChangeTargetItemSubsection($event, item)"
                          [disabled]="isLoading"
                        />
                        <label [for]="'target-item-' + item.value" class="cursor-pointer">{{ item.label }}</label><br/>
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="field col-12 md:col-12">
                  <label for="target-area" class="required">Praça</label>
                  <p-selectButton
                    id="target-area"
                    [options]="targetAreaOptions"
                    [(ngModel)]="targetArea"
                    optionLabel="label"
                    optionValue="value"
                    [disabled]="isLoading"
                    (onChange)="onChangeTargetArea($event)"
                  ></p-selectButton>
                  <div *ngIf="['E', 'M', 'P'].includes(targetArea)" class="pt-3">
                    <p-autoComplete
                      id="target-areas"
                      [(ngModel)]="targetAreas"
                      [suggestions]="filteredTargetAreas"
                      (completeMethod)="filterTargetArea($event)"
                      field="label"
                      [multiple]="true"
                    ></p-autoComplete>
                    <small *ngIf="targetArea === 'E'">Forneça parte do termo que deseja procurar. Ex.: Dis.</small>
                    <small *ngIf="targetArea === 'M'">Forneça parte do termo que deseja procurar. Ex.: Rio.</small>
                    <small *ngIf="targetArea === 'P'">Forneça parte do termo que deseja procurar. Ex.: Ang.</small>
                  </div>
                </div> -->

                <div class="field col-12 md:col-12">
                  <label for="tags" class="required">Tags</label>
                  <p-chips
                    id="tags"
                    [(ngModel)]="tags"
                    separator=","
                    placeholder="Dica: tag 1, tag 2, tag 3"
                    [disabled]="isLoading"
                  ></p-chips>
                  <small class="cursor-default"
                    >Indique palavras-chave que possam servir para pesquisar o
                    assunto, como nome da peça, tipo de peça, segmento de
                    público, conteúdo etc.</small
                  >
                </div>

                <div class="field col-12 md:col-6">
                  <label for="subject" class="required mb-3">Assunto</label>
                  <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                      <p-radioButton
                        name="subject"
                        value="1"
                        [(ngModel)]="subject"
                        inputId="subject-market"
                      ></p-radioButton>
                      <label for="subject-market" class="cursor-pointer ml-2"
                        >Mercadológico</label
                      >
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton
                        name="subject"
                        value="2"
                        [(ngModel)]="subject"
                        inputId="subject-institutional"
                      ></p-radioButton>
                      <label
                        for="subject-institutional"
                        class="cursor-pointer ml-2"
                        >Institucional</label
                      >
                    </div>
                  </div>
                  <!-- <p-selectButton id="subject" [options]="subjectOptions" [(ngModel)]="subject" optionLabel="label"
                    optionValue="value" [disabled]="isLoading"></p-selectButton> -->
                  <!-- <small>O assunto que é abordado com esta peça.</small> -->
                </div>

                <!--                <div class="field col-12 md:col-6">-->
                <!--                  <label for="subject" class="required mb-3">Assunto</label>-->
                <!--                  <div class="flex gap-4 h-2rem">-->
                <!--                    <div>-->
                <!--                      <input id="subject-market" [disabled]="isLoading" type="radio" value="1" [(ngModel)]="subject"/>-->
                <!--                      <label for="subject-market" class="text-lg ml-3">Mercadológico</label>-->
                <!--                    </div>-->
                <!--                    <div>-->
                <!--                      <input id="subject-institutional" [disabled]="isLoading" type="radio" value="2" [(ngModel)]="subject"/>-->
                <!--                      <label for="subject-institutional" class="text-lg ml-3">Permanente</label>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; <p-selectButton id="subject" [options]="subjectOptions" [(ngModel)]="subject" optionLabel="label"-->
                <!--                    optionValue="value" [disabled]="isLoading"></p-selectButton> &ndash;&gt;-->
                <!--                  &lt;!&ndash; <small>O assunto que é abordado com esta peça.</small> &ndash;&gt;-->
                <!--                </div>-->

                <div class="field col-12 md:col-6">
                  <label for="service" class="required">Produto/serviço</label>
                  <input
                    id="service"
                    #serviceInput
                    type="text"
                    pInputText
                    maxlength="300"
                    [(ngModel)]="service"
                    [disabled]="isLoading"
                    (change)="onChangeValidate($event)"
                    (keydown)="onChangeValidate($event)"
                    (keyup)="onChangeValidate($event)"
                  />
                  <!-- <small>Nome do produto ou serviço o qual esta peça representa.</small>&nbsp; -->
                  <app-characters-remaining
                    [field]="serviceInput"
                    [value]="service"
                    [block]="false"
                  ></app-characters-remaining>
                </div>
                <div
                  class="field col-12 md:col-6"
                  #communicationPeriodArea
                  *ngIf="isKindMovie() || isKindSpot() || isKindJingle()"
                >
                  <!-- <h3>Período da ação de comunicação</h3>
                  <div class="p-fluid grid formgrid">
                      <div class="field col-12 md:col-5">
                        <label for="communication-period-start" class="required">Inicio</label>

                      <p-calendar
                        id="communication-period-start"
                        [(ngModel)]="communicationPeriodStart"
                        [showButtonBar]="true"
                        [showIcon]="true"
                        [minDate]="minDate"
                        [readonlyInput]="true"
                        [disabled]="isLoading"
                        [appendTo]="communicationPeriodArea"
                      ></p-calendar>
                    </div>
                    <div  class="field col-12 md:col-5">
                      <label for="communication-period-end" class="required">Fim</label>
                      <p-calendar
                        id="communication-period-end"
                        [(ngModel)]="communicationPeriodEnd"
                        [showButtonBar]="true"
                        [showIcon]="true"
                        [minDate]="communicationPeriodStart"
                        [readonlyInput]="true"
                        [disabled]="isLoading"
                        [appendTo]="communicationPeriodArea"
                        ></p-calendar>
                    </div>
                  </div> -->
                </div>
                <div class="field col-12 md:col-6" *ngIf="!isVirtualType">
                  <label for="product-goal" class="required mb-3"
                    >Finalidade da peça</label
                  >
                  <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                      <p-radioButton
                        name="productGoal"
                        [value]="isVirtualType ? null : 'P'"
                        [(ngModel)]="productGoal"
                        inputId="product-goal-to-produce"
                      ></p-radioButton>
                      <label
                        for="product-goal-to-produce"
                        class="cursor-pointer ml-2"
                        >Para produzir</label
                      >
                    </div>

                    <div class="flex align-items-center">
                      <p-radioButton
                        name="productGoal"
                        value="AF"
                        [(ngModel)]="productGoal"
                        inputId="product-goal-to-download"
                      ></p-radioButton>
                      <label
                        for="product-goal-to-download"
                        class="cursor-pointer ml-2"
                        >Para download</label
                      >
                    </div>
                  </div>
                  <!-- <p-selectButton id="subject" [options]="subjectOptions" [(ngModel)]="subject" optionLabel="label"
                    optionValue="value" [disabled]="isLoading"></p-selectButton> -->
                  <!-- <small>O assunto que é abordado com esta peça.</small> -->
                </div>

                <!--                <div class="field col-12 md:col-6">-->
                <!--                  <label for="product-goal" class="required mb-3">Finalidade da peça</label>-->
                <!--                  <div class="flex h-2rem align-items-center gap-4">-->
                <!--                    <div>-->
                <!--                      <input id="product-goal-produce" [disabled]="isLoading" type="radio" value="P"-->
                <!--                             [(ngModel)]="productGoal"/>-->
                <!--                      <label for="product-goal-produce" class="text-lg ml-3">Para reproduzir</label>-->
                <!--                    </div>-->
                <!--                    <div>-->
                <!--                      <input id="product-goal-download" [disabled]="isLoading" type="radio" value="AF"-->
                <!--                             [(ngModel)]="productGoal"/>-->
                <!--                      <label for="product-goal-download" class="text-lg ml-3">Para download</label>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; <p-selectButton-->
                <!--                    id="product-goal"-->
                <!--                    [options]="productGoalOptions"-->
                <!--                    [(ngModel)]="productGoal"-->
                <!--                    optionLabel="label"-->
                <!--                    optionValue="value"-->
                <!--                    [disabled]="isLoading"-->
                <!--                  ></p-selectButton> &ndash;&gt;-->
                <!--                  <small class="cursor-default">A peça será produzida ou é apenas fornecimento de arte final?</small>-->
                <!--                </div>-->
                <div class="field col-12 md:col-4" *ngIf="showValueProduction">
                  <label for="print-batch-unit-price" class="required"
                    >Custo de produção</label
                  >

                  <input
                    id="unitPrice"
                    type="text"
                    currencyMask
                    pInputText
                    maxlength="150"
                    [(ngModel)]="printBatch.unitPrice"
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    [disabled]="isLoading"
                  />
                </div>
                <div class="field col-12 md:col-12">
                  <label for="specification" class="required"
                    >Especificação técnica da peça</label
                  >
                  <textarea
                    id="specification"
                    #specificationInput
                    rows="3"
                    cols="30"
                    maxlength="65535"
                    pInputTextarea
                    [(ngModel)]="specification"
                    [autoResize]="true"
                    [disabled]="isLoading"
                    (change)="onChangeValidate($event)"
                    (keydown)="onChangeValidate($event)"
                    (keyup)="onChangeValidate($event)"
                  ></textarea>
                  <small class="cursor-default" *ngIf="productGoal == 'AF'"
                    >Se peça para download, especificar formato e finalidade de
                    uso. Se for uma arte para produção local, indicar as
                    especificacões técnicas que o fornecedor deverá
                    utilizar.</small
                  >
                  <small class="cursor-default" *ngIf="productGoal != 'AF'"
                    >Detalhamento das características da peça. Quando houver
                    DOAC, copiar e colar o descritivo.</small
                  >&nbsp;
                  <app-characters-remaining
                    [field]="specificationInput"
                    [value]="specification"
                    [block]="false"
                  ></app-characters-remaining>
                </div>

                <!--
                <div class="field col-12 md:col-12" *ngIf="productGoal === 'AF'">
                  <label for="branches">Prefixos</label>
                  <p-chips id="branches" [(ngModel)]="branches" [separator]="chipsSeparatorExp" placeholder=""
                           [disabled]="isLoading"></p-chips>
                  <small class="cursor-default">Informe o(s) código(s) do(s) prefixo(s) os quais deseja vincular de
                    forma exclusiva a peça. <strong>Deixe vazio caso seja para todos os prefixos.</strong></small>
                </div>
                -->
                <!-- SHOWCASE: Start -->
                <div class="field col-12 md:col-12">
                  <label for="showcase" class="required">{{
                    isMultipleImages ? "Mostruário" : "Thumbnail"
                  }}</label>
                  <p></p>
                  <p-fileUpload
                    id="showcase"
                    class="input-custom uppercase"
                    #showcaseItemInput
                    name="showcase[]"
                    mode="basic"
                    [multiple]="isMultipleImages"
                    chooseLabel="Adicionar"
                    accept=".jpg,.png,.gif,.bmp"
                    [customUpload]="true"
                    [multiple]="true"
                    (uploadHandler)="onShowcaseItemUpload($event)"
                    [auto]="true"
                    [disabled]="isLoading"
                  >
                  </p-fileUpload>
                  <div class="mt-1">
                    <!--
                    <small class="cursor-default"><strong>A imagem da capa da galeria não deve ser acompanhada de texto
                      algum.</strong>&nbsp;Deve ser apenas fundo cinza (5% de preto) e imagem do mockup da peça. A peça
                      deve ter o formato de 496px x 496px. Solicitar imagem ao criador de conteúdo.</small>
                    -->
                    <!--
                    <small class="cursor-default"><strong>A capa da galeria não deve ter texto.</strong> As imagens
                      precisam ser de todas as faces e/ou páginas da peça.</small>
                    -->

                    <small class="cursor-default"
                      >Adicione imagens que mostre todas as faces da peça que
                      será produzida para que o solicitante possa conhece-la e
                      também ler seus respectivos textos.</small
                    ><br />
                    <small class="cursor-default"
                      >Formatos aceitos:
                      <strong>jpg, png, gif, bmp</strong></small
                    >
                  </div>
                </div>

                <div
                  class="col-12 md:col-12 flex-wrap showcase"
                  *ngIf="showcase.length > 0"
                  cdkDropList
                  (cdkDropListDropped)="onShowcaseItemDrop($event)"
                >
                  <ng-container *ngFor="let item of showcase; let i = index">
                    <ng-container *ngIf="!item.remove">
                      <div
                        class="mr-2 mb-2 text-center item flex justify-content-between align-items-center"
                        [class.selected]="i == 0"
                        cdkDrag
                      >
                        <img [src]="item.content" width="120" />
                        <h6>
                          {{ item.name }}
                        </h6>
                        <div class="row">
                          <div class="flex">
                            <button
                              *ngIf="!isEdit"
                              pButton
                              type="button"
                              pTooltip="Editar"
                              tooltipPosition="top"
                              icon="fa-solid fa-pencil"
                              class="p-button-success flex-1 p-button btn-sm col"
                              (click)="editShowCaseItem(item, i)"
                            ></button>
                            <button
                              pButton
                              type="button"
                              pTooltip="Remover"
                              tooltipPosition="top"
                              icon="fa-solid fa-trash"
                              class="p-button-danger flex-1 p-button btn-sm col"
                              (click)="removeShowcaseItem($event, item, i)"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- SHOWCASE: End -->

                <!-- CUSTOM CARD: Start -->
                <ng-container *ngIf="isKindCustomCard()">
                  <div class="field col-12 md:col-12">
                    <label for="custom-card" class="required"
                      >Cartão personalizável</label
                    >
                    <p-fileUpload
                      id="custom-card"
                      #customCardInput
                      name="customCard[]"
                      mode="basic"
                      chooseLabel="Selecionar imagem"
                      accept=".jpg,.png,.gif,.bmp"
                      [customUpload]="true"
                      (uploadHandler)="onCustomCardUpload($event)"
                      [auto]="true"
                      [disabled]="isLoading"
                    ></p-fileUpload>
                    <div class="mt-1">
                      <small class="cursor-default"
                        >Para o cartão personalizável é permitido selecionar um
                        determinado local da imagem que pode ser fornecido uma
                        informação na hora que o solicitante for realizar o
                        download.</small
                      ><br />
                      <small class="cursor-default"
                        >Formatos aceitos:
                        <strong>jpg, png, gif, bmp</strong></small
                      >
                    </div>
                  </div>

                  <div class="col-12 md:col-12 custom-card" *ngIf="customCard">
                    <div class="mr-2 mb-2 text-center item selected">
                      <img
                        [src]="customCard.content"
                        width="496"
                        height="496"
                      /><br />
                      <a
                        href="#"
                        pTooltip="Editar"
                        tooltipPosition="top"
                        (click)="editCustomCard($event)"
                      >
                        <i class="fa-solid fa-pencil"></i
                      ></a>
                    </div>
                  </div>
                </ng-container>
                <!-- CUSTOM CARD: End -->

                <!-- MOVIE: Start -->
                <ng-container *ngIf="isKindMovie()">
                  <div class="field col-12 md:col-12">
                    <label for="movie" class="required">Filme</label>
                    <p-fileUpload
                      id="movie"
                      #movieInput
                      name="movie[]"
                      mode="basic"
                      chooseLabel="Selecionar filme"
                      accept=".mp4"
                      [customUpload]="true"
                      (uploadHandler)="onMovieUpload($event)"
                      [auto]="true"
                      [disabled]="isLoading"
                    ></p-fileUpload>
                    <div class="mt-1">
                      <small class="cursor-default"
                        >O tamanho do arquivo não pode ter mais que 300mb para
                        ser aceito no envio do WhatsApp.</small
                      >
                    </div>
                    <div class="text-center" *ngIf="isLoading">
                      <p-progressSpinner
                        styleClass="w-4rem h-4rem mt-4 block"
                        strokeWidth="2"
                        animationDuration=".5s"
                      >
                      </p-progressSpinner>
                      <span class="small">{{ uploadProgress }}%</span>
                    </div>
                  </div>

                  <div
                    class="p-fluid grid w-full"
                    *ngIf="hasMovie && !isLoading"
                  >
                    <div class="col-12 md:col-6 text-center">
                      <video width="400" controls class="surface-card">
                        <source src="" id="movie-player" />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div
                      class="col-12 md:col-4 text-left"
                      *ngIf="movieInfo && movieInfo?.name"
                    >
                      <div>
                        <strong class="block mb-2 ow">{{
                          movieInfo?.name
                        }}</strong>
                        <span class="block mb-2"
                          ><span class="small">Qualidade:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ movieInfo?.quality }} ({{ movieInfo?.width }} x
                            {{ movieInfo?.height }})</span
                          ></span
                        >
                        <span class="block mb-2"
                          ><span class="small">Proporção:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ movieInfo?.aspectRatio }}</span
                          ></span
                        >
                        <span class="block mb-2"
                          ><span class="small">Duração:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ movieInfo?.duration }}</span
                          ></span
                        >
                        <span class="block mb-2"
                          ><span class="small">Tamanho:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ movieInfo?.size | fileSize }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- MOVIE: End -->

                <!-- SPOT/JINGLE: Start -->
                <ng-container *ngIf="isKindSpot() || isKindJingle()">
                  <div class="field col-12 md:col-12">
                    <label for="audio" class="required">Áudio</label>
                    <p-fileUpload
                      id="audio"
                      #audioInput
                      name="audio[]"
                      mode="basic"
                      chooseLabel="Selecionar áudio"
                      accept=".mp3"
                      [customUpload]="true"
                      (uploadHandler)="onAudioUpload($event)"
                      [auto]="true"
                      [disabled]="isLoading"
                    ></p-fileUpload>
                    <div class="mt-1">
                      <small class="cursor-default"
                        >O tamanho do arquivo não pode ter mais que 10mb.</small
                      >
                    </div>
                    <div class="text-center" *ngIf="isLoading">
                      <p-progressSpinner
                        styleClass="w-4rem h-4rem mt-4 block"
                        strokeWidth="2"
                        animationDuration=".5s"
                      ></p-progressSpinner>
                      <span class="small">{{ uploadProgress }}%</span>
                    </div>
                  </div>

                  <div
                    class="p-fluid grid w-full"
                    *ngIf="hasAudio && !isLoading"
                  >
                    <div class="col-12 md:col-6 text-center">
                      <audio controls>
                        <source src="" id="audio-player" />
                        Your browser does not support HTML5 audio.
                      </audio>
                    </div>
                    <div
                      class="col-12 md:col-4 text-left"
                      *ngIf="audioInfo && audioInfo?.name"
                    >
                      <div>
                        <strong class="block mb-2 ow">{{
                          audioInfo?.name
                        }}</strong>
                        <span class="block mb-2"
                          ><span class="small">Duração:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ audioInfo?.duration }}</span
                          ></span
                        >
                        <span class="block mb-2"
                          ><span class="small">Tamanho:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ audioInfo?.size | fileSize }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- SPOT/JINGLE: End -->

                <!-- OTHER KIND: Start -->
                <ng-container *ngIf="isKindOther() && productGoal === 'AF'">
                  <div class="field col-12 md:col-12">
                    <label for="file" class="required">Arte final</label>
                    <p-fileUpload
                      id="file"
                      #fileInput
                      name="file[]"
                      mode="basic"
                      chooseLabel="Selecionar arquivo"
                      [customUpload]="true"
                      (uploadHandler)="onFileUpload($event)"
                      [auto]="true"
                      [disabled]="isLoading"
                    ></p-fileUpload>
                    <div class="mt-1 cursor-default">
                      <small class="cursor-default"
                        >Arquivo finalizado em alta resolução. O tamanho do
                        arquivo não pode ter mais que 100mb.</small
                      >
                    </div>
                    <div class="text-center cursor-default" *ngIf="isLoading">
                      <p-progressSpinner
                        styleClass="w-4rem h-4rem mt-4 block"
                        strokeWidth="2"
                        animationDuration=".5s"
                      ></p-progressSpinner>
                      <span class="small">{{ uploadProgress }}%</span>
                    </div>
                  </div>

                  <div
                    class="p-fluid grid w-full p-2"
                    *ngIf="hasFile && !isLoading"
                  >
                    <div
                      class="col-12 md:col-12 text-left cursor-default"
                      *ngIf="fileInfo && fileInfo?.name"
                    >
                      <div>
                        <strong class="block mb-2 ow">{{
                          fileInfo?.name
                        }}</strong>
                        <span class="block mb-2"
                          ><span class="small">Tamanho:</span>&nbsp;<span
                            class="monospaced font-bold"
                            >{{ fileInfo?.size | fileSize }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- OTHER KIND: End -->
              </div>
            </div>
          </p-tabPanel>
          <!-- 1. TAB GENERAL (INFORMATION): End -->

          <!-- 2. TAB COPYRIGHT: Start -->
          <p-tabPanel [disabled]="isEdit && (isLoading || !tabOneValidate)">
            <ng-template pTemplate="header">
              <!-- <i class="fa-solid fa-copyright"></i>&nbsp; -->
              <div
                class="btn-custom border-left-none"
                [ngClass]="{
                  'border-round-right':
                    productGoal == 'AF' || productGoal == null
                }"
              >
                2. {{ canShowTabImage() ? "Imagem" : "Meios de divulgação" }}
              </div>
            </ng-template>
            <div class="p-fluid grid formgrid">
              <div
                class="field col-12 md:col-4"
                *ngIf="canShowTabImage()"
                [class.error]="rightsExpireAtIndeterminate === null"
              >
                <label for="allowed-until-indeterminate" class="required"
                  >Validade indeterminada</label
                >
                <p-selectButton
                  id="allowed-until-indeterminate"
                  [options]="rightsExpireAtIndeterminateOptions"
                  [(ngModel)]="rightsExpireAtIndeterminate"
                  optionLabel="label"
                  optionValue="value"
                  [disabled]="isLoading"
                ></p-selectButton>
                <small
                  >A validade do direito de uso da foto é para sempre?</small
                >
              </div>
              <div
                class="field col-12 md:col-4"
                #allowedUntilArea
                *ngIf="canShowRightsExpireAtArea()"
              >
                <label for="allowed-until" class="required"
                  >Validade do direito de uso da foto</label
                >
                <p-calendar
                  id="allowed-until"
                  [(ngModel)]="rightsExpireAt"
                  [showButtonBar]="true"
                  [showIcon]="true"
                  [minDate]="minDate"
                  [readonlyInput]="true"
                  [disabled]="isLoading"
                  [appendTo]="allowedUntilArea"
                ></p-calendar>
              </div>

              <div class="field col-12 md:col-12">
                <label for="means" class="required">Meio de divulgação</label>
                <div
                  id="means"
                  *ngIf="meansOptions.length > 0"
                  class="flex flex-wrap gap-2 mt-3"
                >
                  <div *ngFor="let item of meansOptions" class="field-checkbox">
                    <!--
                    <p-checkbox name="means" [value]="item" [(ngModel)]="means" [inputId]="item.value"
                                [disabled]="isLoading"></p-checkbox>
                    <label [for]="item.value">{{ item.label }}</label>
                    -->
                    <input
                      type="checkbox"
                      [id]="'means-item-' + item.value"
                      [name]="'means-item-' + item.value"
                      [value]="item.value"
                      [checked]="means.includes(item.value)"
                      (change)="onChangeMeansItem($event, item)"
                      [disabled]="isLoading"
                    />
                    <label
                      [for]="'means-item-' + item.value"
                      class="cursor-pointer"
                      >{{ item.label }}</label
                    ><br />
                  </div>
                </div>
              </div>
              <div class="field col-12 md:col-12">
                <label for="target-area" class="required">Praça</label>
                <p-selectButton
                  id="target-area"
                  [options]="targetAreaOptions"
                  [(ngModel)]="targetArea"
                  optionLabel="label"
                  optionValue="value"
                  [disabled]="isLoading"
                  (onChange)="onChangeTargetArea($event)"
                ></p-selectButton>

                <div *ngIf="['E', 'M', 'P'].includes(targetArea)" class="pt-3">
                  <p-autoComplete
                    id="target-areas"
                    [(ngModel)]="targetAreas"
                    [suggestions]="filteredTargetAreas"
                    (completeMethod)="filterTargetArea($event)"
                    field="label"
                    [multiple]="true"
                  ></p-autoComplete>
                  <small *ngIf="targetArea === 'E'"
                    >Forneça parte do termo que deseja procurar. Ex.:
                    Dis.</small
                  >
                  <small *ngIf="targetArea === 'M'"
                    >Forneça parte do termo que deseja procurar. Ex.:
                    Rio.</small
                  >
                  <small *ngIf="targetArea === 'P'"
                    >Forneça parte do termo que deseja procurar. Ex.:
                    Ang.</small
                  >
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!-- 2. TAB COPYRIGHT: End -->

          <!-- 3. TAB BATCH PRINTING AND DISTRIBUTION: Start -->
          <p-tabPanel
            *ngIf="canShowTabBatchPrintingAndDistribution()"
            [disabled]="isEdit && (isLoading || !tabTwoValidate)"
          >
            <ng-template pTemplate="header">
              <!-- <i class="fa-solid fa-truck-fast"></i>&nbsp; -->
              <div class="btn-custom border-round-right border-left-none">
                3.
                {{
                  isVirtualType
                    ? "Informações adicionais"
                    : "Impressão e distribuição"
                }}
              </div>
            </ng-template>

            <!--
            <p-tabView>
              <p-tabPanel header="1o Lote" [disabled]="isLoading">
                <div class="p-fluid grid formgrid">
                  <div class="field col-12 md:col-6">
                    <label for="publicity-agency" class="required">Agência de publicidade</label>
                    <p-dropdown id="publicity-agency" [options]="publicityAgencyOptions" [(ngModel)]="publicityAgency"
                                optionLabel="label" [filter]="true" filterBy="label" [showClear]="true"
                                placeholder="Selecione uma agência de publicidade" [disabled]="isLoading"></p-dropdown>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="supplier" class="required">Fornecedor</label>
                    <p-dropdown id="supplier" [options]="supplierOptions" [(ngModel)]="supplier" optionLabel="label"
                                [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecione um fornecedor"
                                [disabled]="isLoading"></p-dropdown>
                  </div>
                  <div class="field col-12 md:col-4">
                    <label for="custom-card-maxlength" class="required">Quantidade</label>
                    <p-inputNumber inputId="custom-card-maxlength" [(ngModel)]="customCardMaxLength" [showButtons]="true"
                                   buttonLayout="horizontal" [allowEmpty]="false" [showButtons]="true" [min]="0"
                                   spinnerMode="horizontal" [disabled]="isLoading"></p-inputNumber>
                  </div>
                  <div class="field col-12 md:col-4">
                    <label for="custom-card-maxlength" class="required">Valor unitário</label>
                    <p-inputNumber inputId="custom-card-maxlength" [(ngModel)]="customCardMaxLength" [showButtons]="true"
                                   buttonLayout="horizontal" [allowEmpty]="false" [showButtons]="true" [min]="0"
                                   spinnerMode="horizontal" [disabled]="isLoading" mode="currency"
                                   currency="BRL"></p-inputNumber>
                  </div>
                  <div class="field col-12 md:col-4" #estimatedArrivalArea>
                    <label for="estimated-arrival" class="required">Data prevista de chegada</label>
                    <p-calendar id="estimated-arrival" [(ngModel)]="expireAt" [showButtonBar]="true" [showIcon]="true"
                                [minDate]="minDate" [readonlyInput]="false" [disabled]="isLoading"
                                [appendTo]="estimatedArrivalArea"></p-calendar>
                  </div>
                </div>
              </p-tabPanel>
            </p-tabView>
            -->
            <div class="flex justify-content-start mb-4">
              <button
                pButton
                pRipple
                label="lote {{ i + 1 }}"
                class="mr-2"
                (click)="filterPrintBatch(item)"
                *ngFor="let item of printBatches; let i = index"
              ></button>
              <p-button
                icon="fa-solid fa-plus"
                styleClass="p-button-info"
                pTooltip="Adicionar reimpressão"
                tooltipPosition="bottom"
                (click)="newPrintBatch()"
                *ngIf="id !== null"
              ></p-button>
            </div>

            <p-divider></p-divider>

            <p-messages severity="warn" *ngIf="printBatch.isConfirmed">
              <ng-template pTemplate>
                <p>Esse lote já esta confirmado, não pode ser editado!</p>
              </ng-template>
            </p-messages>

            <!-- <div class="field col-12 md:col-4" *ngIf="printBatch.isConfirmed">
              <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
            </div> -->
            <div class="p-fluid grid formgrid pt-2">
              <!--
              <div class="field col-12 md:col-6">
                <label for="print-batch-publicity-agency" class="required">Agência de publicidade</label>
                <p-dropdown
                  id="print-batch-publicity-agency"
                  [options]="publicityAgencyOptions"
                  [(ngModel)]="printBatch.publicityAgency"
                  optionLabel="label"
                  [filter]="true"
                  filterBy="label"
                  [showClear]="true"
                  placeholder="Selecione uma agência de publicidade"
                  [disabled]="isLoading"
                ></p-dropdown>
              </div>
              -->
              <!--
              <div class="field col-12 md:col-6">
                <label for="print-batch-supplier" class="required">Fornecedor</label>
                <p-dropdown
                  id="print-batch-supplier"
                  [options]="supplierOptions"
                  [(ngModel)]="printBatch.supplier"
                  optionLabel="label"
                  [filter]="true"
                  filterBy="label"
                  [showClear]="true"
                  placeholder="Selecione um fornecedor"
                  [disabled]="isLoading"
                ></p-dropdown>
              </div>
              -->

              <div class="field col-12 md:col-4">
                <label for="print-batch-quantity" class="required"
                  >Quantidade</label
                >
                <p-inputNumber
                  inputId="print-batch-quantity"
                  [(ngModel)]="printBatch.quantity"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  [allowEmpty]="false"
                  [min]="0"
                  spinnerMode="horizontal"
                  [disabled]="isLoading || printBatch.isConfirmed"
                  incrementButtonIcon="fa-solid fa-plus"
                  decrementButtonIcon="fa-solid fa-minus"
                ></p-inputNumber>
              </div>
              <div class="field col-12 md:col-4">
                <label for="print-batch-unit-price" class="required"
                  >Valor unitário</label
                >

                <input
                  id="unitPrice"
                  type="text"
                  currencyMask
                  pInputText
                  maxlength="150"
                  [(ngModel)]="printBatch.unitPrice"
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                  [disabled]="isLoading || printBatch.isConfirmed"
                />
              </div>
              <div class="field col-12 md:col-4" #printBatchExpectedOnArea>
                <label for="print-batch-expected-on" class="required"
                  >Data prevista de chegada</label
                >
                <p-calendar
                  id="print-batch-expected-on"
                  [(ngModel)]="printBatch.expectedOn"
                  [showButtonBar]="true"
                  [showIcon]="true"
                  [minDate]="minDate"
                  [readonlyInput]="false"
                  [disabled]="isLoading || printBatch.isConfirmed"
                  [appendTo]="printBatchExpectedOnArea"
                ></p-calendar>
              </div>
            </div>

            <p-divider></p-divider>

            <div class="field col-12 md:col-12" *ngIf="!isVirtualType">
              <label for="target" class="required">Forma de distribuição</label>
              <div
                *ngIf="deliveryWayOptions.length > 0"
                class="flex flex-wrap gap-2 mt-3"
              >
                <div
                  *ngFor="let item of deliveryWayOptions"
                  class="field-checkbox"
                >
                  <div [pTooltip]="item.data">
                    <input
                      type="checkbox"
                      [id]="'delivery-way-item-' + item.value"
                      [name]="'delivery-way-' + item.value"
                      [value]="item.value"
                      [checked]="isDeliveryWayChecked(item)"
                      (change)="onChangeDeliveryWayItem($event, item)"
                      [disabled]="isLoading"
                    />
                    <label
                      [for]="'delivery-way-item-' + item.value"
                      class="cursor-pointer"
                      >{{ item.label }}</label
                    ><br />
                    <!--
                    <input type="checkbox" [id]="'means-item-' + item.value" [name]="'means-item-' + item.value"
                      [value]="item.value" [checked]="means.includes(item.value)"
                      (change)="onChangeMeansItem($event, item)" [disabled]="isLoading">
                    <label [for]="'means-item-' + item.value" class="cursor-pointer"> {{ item.label }}</label><br>
                    -->
                  </div>
                </div>
              </div>
            </div>

            <p-tabView>
              <p-tabPanel
                [disabled]="isLoading"
                *ngIf="deliveryWaysExists('C')"
              >
                <ng-template pTemplate="header">
                  <!-- <i class="fa-solid fa-layer-group"></i>&nbsp; -->
                  <div class="btn-custom">1. Estoque para grupos</div>
                </ng-template>
                <p-table
                  [value]="deliveryWayByGroup"
                  styleClass="p-datatable-striped"
                  [tableStyle]="{ 'min-width': '50rem' }"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="cursor-default" style="width: 300px">
                        Classificação
                      </th>
                      <th class="cursor-default" style="width: 100px">
                        Quantidade
                      </th>
                      <th class="cursor-default" style="width: 100px">
                        Dependências
                      </th>
                      <th class="cursor-default" style="width: 100px">Total</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-distribution>
                    <tr>
                      <td class="cursor-default">
                        {{ distribution.detail.label }}
                      </td>
                      <td>
                        <p-inputNumber
                          inputId="print-batch-quantity"
                          [(ngModel)]="distribution.quantity"
                          [showButtons]="true"
                          buttonLayout="horizontal"
                          [allowEmpty]="false"
                          [min]="0"
                          spinnerMode="horizontal"
                          [disabled]="isLoading || printBatch.isConfirmed"
                          incrementButtonIcon="fa-solid fa-plus"
                          decrementButtonIcon="fa-solid fa-minus"
                          class="w-full"
                        ></p-inputNumber>
                      </td>
                      <td class="cursor-default text-right">
                        {{ distribution.detail.branches | number : "1.0-0" }}
                      </td>
                      <td class="cursor-default text-right">
                        {{
                          distribution.quantity * distribution.detail.branches
                            | number : "1.0-0"
                        }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-tabPanel>
              <p-tabPanel
                [disabled]="isLoading"
                *ngIf="deliveryWaysExists('A')"
              >
                <ng-template pTemplate="header">
                  <!-- <i class="fa-solid fa-bolt"></i>&nbsp; -->
                  <div class="btn-custom">2. Distribuição automática</div>
                </ng-template>
                <p-button
                  label="Modelo de planilha para distribuição automática"
                  icon="fa-solid fa-file-excel"
                  styleClass="p-button-link"
                  [disabled]="printBatch.isConfirmed"
                  (onClick)="
                    download(
                      '/assets/docs/modelo_importacao_distro_automatica.xlsx'
                    )
                  "
                ></p-button>
                <div class="field col-12 md:col-12">
                  <p-fileUpload
                    id="delivery-way-automatic"
                    #deliveryWayAutomaticInput
                    name="deliveryWayAutomatic[]"
                    mode="basic"
                    chooseLabel="Enviar planilha com as informações atualizadas"
                    accept=".xlsx"
                    [customUpload]="true"
                    (uploadHandler)="onDeliveryWayAutomaticUpload($event)"
                    [auto]="true"
                    [disabled]="isLoading || printBatch.isConfirmed"
                    chooseIcon="fa-solid fa-upload"
                  ></p-fileUpload>
                  <div class="mt-1">
                    <small
                      ><strong
                        >Somente aceita o arquivo no formato Excel
                        (.xlsx)</strong
                      >
                      e o arquivo deve ser baseado no modelo acima.</small
                    >
                  </div>
                </div>

                <p-table
                  [value]="deliveryWayAutomatic"
                  styleClass="p-datatable-striped mb-3"
                  [tableStyle]="{ 'min-width': '50rem' }"
                  *ngIf="deliveryWayAutomatic.length > 0"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="cursor-default" style="width: 300px">
                        Prefixo
                      </th>
                      <th class="cursor-default" style="width: 100px">
                        Quantidade
                      </th>
                      <th class="cursor-default" style="width: 40px">Ação</th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-distribution
                    let-i="rowIndex"
                  >
                    <tr>
                      <td class="cursor-default">
                        {{ distribution.detail.label }}
                      </td>
                      <td>
                        <p-inputNumber
                          inputId="print-batch-quantity"
                          [(ngModel)]="distribution.quantity"
                          [showButtons]="true"
                          buttonLayout="horizontal"
                          [allowEmpty]="false"
                          [min]="0"
                          spinnerMode="horizontal"
                          [disabled]="isLoading || printBatch.isConfirmed"
                          incrementButtonIcon="fa-solid fa-plus"
                          decrementButtonIcon="fa-solid fa-minus"
                          class="w-full"
                        ></p-inputNumber>
                      </td>
                      <td>
                        <p-button
                          icon="fa-solid fa-times"
                          styleClass="p-button p-button-danger p-button-text"
                          pTooltip="Remover"
                          tooltipPosition="left"
                          [disabled]="printBatch.isConfirmed"
                          (onClick)="
                            removeDeliveryWayAutomatic($event, distribution, i)
                          "
                          *ngIf="deliveryWayCanBeRemoved(distribution)"
                        ></p-button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <div class="p-fluid grid formgrid">
                  <div class="field col-12 md:col-12">
                    <label
                      for="print-batch-restricted-prefix1"
                      [class.required]="deliveryWayAutomatic.length === 0"
                    >
                      Prefixo</label
                    >
                    <p-autoComplete
                      id="print-batch-restricted-prefix1"
                      [(ngModel)]="printBatchRestrictedPrefix"
                      [suggestions]="printBatchRestrictedPrefixOptions"
                      (completeMethod)="filterDeliveryWayAutomatic($event)"
                      field="label"
                      [disabled]="isLoading || printBatch.isConfirmed"
                      (onSelect)="addDeliveryWayAutomatic($event)"
                    ></p-autoComplete>
                    <!--
                    <small class="cursor-default">Digite o início do nome e selecione uma opção. Pode usar o teclado,
                      é mais fácil. Para escolher pressione a tecla Enter.</small>
                    -->
                    <small class="cursor-default"
                      >Digite o prefixo da dependência de destino ou algo
                      similar. Pode usar o teclado, é mais fácil. Para escolher
                      pressione a tecla Enter.</small
                    >
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel
                [disabled]="isLoading"
                *ngIf="deliveryWaysExists('D')"
              >
                <ng-template pTemplate="header">
                  <!-- <i class="fa-solid fa-hand-holding"></i>&nbsp; -->
                  <div class="btn-custom">3. Distribuição direta</div>
                  <span></span>
                </ng-template>
                <p-button
                  label="Modelo de planilha para distribuição direta"
                  icon="fa-solid fa-file-excel"
                  styleClass="p-button-link"
                  [disabled]="printBatch.isConfirmed"
                  (onClick)="
                    download(
                      '/assets/docs/modelo_importacao_distro_direta.xlsx'
                    )
                  "
                ></p-button>
                <div class="field col-12 md:col-12">
                  <p-fileUpload
                    id="delivery-way-direct"
                    #deliveryWayDirectInput
                    name="deliveryWayDirect[]"
                    mode="basic"
                    chooseLabel="Enviar planilha com as informações atualizadas"
                    accept=".xlsx"
                    [customUpload]="true"
                    (uploadHandler)="onDeliveryWayDirectUpload($event)"
                    [auto]="true"
                    [disabled]="isLoading || printBatch.isConfirmed"
                    chooseIcon="fa-solid fa-upload"
                  ></p-fileUpload>
                  <div class="mt-1">
                    <small
                      ><strong
                        >Somente aceita o arquivo no formato Excel
                        (.xlsx)</strong
                      >
                      e o arquivo deve ser baseado no modelo acima.</small
                    >
                  </div>
                </div>

                <p-table
                  [value]="deliveryWayDirect"
                  styleClass="p-datatable-striped mb-3"
                  [tableStyle]="{ 'min-width': '50rem' }"
                  *ngIf="deliveryWayDirect.length > 0"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="cursor-default" style="width: 300px">
                        Prefixo
                      </th>
                      <th class="cursor-default" style="width: 100px">
                        Quantidade
                      </th>
                      <th class="cursor-default" style="width: 160px">
                        Data de entrega
                      </th>
                      <th class="cursor-default" style="width: 40px">Ação</th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-distribution
                    let-i="rowIndex"
                  >
                    <tr>
                      <td class="cursor-default">
                        {{ distribution.detail.label }}
                      </td>
                      <td>
                        <p-inputNumber
                          inputId="print-batch-quantity"
                          [(ngModel)]="distribution.quantity"
                          [showButtons]="true"
                          buttonLayout="horizontal"
                          [allowEmpty]="false"
                          [min]="0"
                          spinnerMode="horizontal"
                          [disabled]="isLoading || printBatch.isConfirmed"
                          incrementButtonIcon="fa-solid fa-plus"
                          decrementButtonIcon="fa-solid fa-minus"
                          class="w-full"
                        ></p-inputNumber>
                      </td>
                      <td>
                        <p-calendar
                          [(ngModel)]="distribution.deliveryAt"
                          [showButtonBar]="true"
                          [showIcon]="true"
                          [minDate]="minDate"
                          [disabled]="printBatch.isConfirmed"
                          [readonlyInput]="false"
                          appendTo="body"
                        ></p-calendar>
                      </td>
                      <td>
                        <p-button
                          icon="fa-solid fa-times"
                          styleClass="p-button p-button-danger p-button-text"
                          pTooltip="Remover"
                          [disabled]="printBatch.isConfirmed"
                          tooltipPosition="left"
                          (onClick)="
                            removeDeliveryWayDirect($event, distribution, i)
                          "
                          *ngIf="deliveryWayCanBeRemoved(distribution)"
                        ></p-button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <div class="p-fluid grid formgrid">
                  <div class="field col-12 md:col-12">
                    <label
                      for="print-batch-restricted-prefix2"
                      [class.required]="deliveryWayDirect.length === 0"
                      >Prefixo</label
                    >
                    <p-autoComplete
                      id="print-batch-restricted-prefix2"
                      [(ngModel)]="printBatchRestrictedPrefix"
                      [suggestions]="printBatchRestrictedPrefixOptions"
                      (completeMethod)="filterDeliveryWayDirect($event)"
                      field="label"
                      [disabled]="isLoading || printBatch.isConfirmed"
                      (onSelect)="addDeliveryWayDirect($event)"
                    ></p-autoComplete>
                    <!--
                    <small class="cursor-default">Digite o início do nome e selecione uma opção. Pode usar o teclado,
                      é mais fácil. Para escolher pressione a tecla Enter.</small>
                    -->
                    <small class="cursor-default"
                      >Digite o prefixo da dependência de destino ou algo
                      similar. Pode usar o teclado, é mais fácil. Para escolher
                      pressione a tecla Enter.</small
                    >
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel
                [disabled]="isLoading"
                *ngIf="deliveryWaysExists('P')"
              >
                <ng-template pTemplate="header">
                  <!-- <i class="fa-solid fa-circle-xmark"></i>&nbsp; -->
                  <div class="btn-custom">4. Estoque restrito</div>
                </ng-template>
                <p-button
                  label="Modelo de planilha para estoque restrito"
                  icon="fa-solid fa-file-excel"
                  styleClass="p-button-link"
                  [disabled]="printBatch.isConfirmed"
                  (onClick)="
                    download(
                      '/assets/docs/modelo_importacao_estoque_restrito.xlsx'
                    )
                  "
                ></p-button>
                <div class="field col-12 md:col-12">
                  <p-fileUpload
                    id="delivery-way-restrict"
                    #deliveryWayRestrictInput
                    name="deliveryWayRestrict[]"
                    mode="basic"
                    chooseLabel="Enviar planilha com as informações atualizadas"
                    accept=".xlsx"
                    [customUpload]="true"
                    (uploadHandler)="onDeliveryWayRestrictUpload($event)"
                    [auto]="true"
                    [disabled]="isLoading || printBatch.isConfirmed"
                    chooseIcon="fa-solid fa-upload"
                  ></p-fileUpload>
                  <div class="mt-1">
                    <small
                      ><strong
                        >Somente aceita o arquivo no formato Excel
                        (.xlsx)</strong
                      >
                      e o arquivo deve ser baseado no modelo acima.</small
                    >
                  </div>
                </div>

                <p-table
                  [value]="deliveryWayRestrict"
                  styleClass="p-datatable-striped mb-3"
                  [tableStyle]="{ 'min-width': '50rem' }"
                  *ngIf="deliveryWayRestrict.length > 0"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="cursor-default" style="width: 300px">
                        Prefixo
                      </th>
                      <th class="cursor-default" style="width: 100px">
                        Quantidade
                      </th>
                      <th class="cursor-default" style="width: 40px">Ação</th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-distribution
                    let-i="rowIndex"
                  >
                    <tr>
                      <td class="cursor-default">
                        {{ distribution.detail.label }}
                      </td>
                      <td>
                        <p-inputNumber
                          inputId="print-batch-quantity"
                          [(ngModel)]="distribution.quantity"
                          [showButtons]="true"
                          buttonLayout="horizontal"
                          [allowEmpty]="false"
                          [min]="0"
                          spinnerMode="horizontal"
                          [disabled]="isLoading || printBatch.isConfirmed"
                          incrementButtonIcon="fa-solid fa-plus"
                          decrementButtonIcon="fa-solid fa-minus"
                          class="w-full"
                        ></p-inputNumber>
                      </td>
                      <td>
                        <p-button
                          icon="fa-solid fa-times"
                          styleClass="p-button p-button-danger p-button-text"
                          pTooltip="Remover"
                          [disabled]="printBatch.isConfirmed"
                          tooltipPosition="left"
                          (onClick)="
                            removeDeliveryWayRestrict($event, distribution, i)
                          "
                        ></p-button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <div class="p-fluid grid formgrid">
                  <div class="field col-12 md:col-12">
                    <label
                      for="print-batch-restricted-prefix3"
                      [class.required]="deliveryWayRestrict.length === 0"
                    >
                      Prefixo</label
                    >
                    <p-autoComplete
                      id="print-batch-restricted-prefix3"
                      [(ngModel)]="printBatchRestrictedPrefix"
                      [suggestions]="printBatchRestrictedPrefixOptions"
                      (completeMethod)="filterDeliveryWayRestrict($event)"
                      field="label"
                      [disabled]="isLoading || printBatch.isConfirmed"
                      (onSelect)="addDeliveryWayRestrict($event)"
                    ></p-autoComplete>
                    <!--
                    <small class="cursor-default">Digite o início do nome e selecione uma opção. Pode usar o teclado,
                      é mais fácil. Para escolher pressione a tecla Enter.</small>
                    -->
                    <small class="cursor-default"
                      >Digite o prefixo da dependência de destino ou algo
                      similar. Pode usar o teclado, é mais fácil. Para escolher
                      pressione a tecla Enter.</small
                    >
                  </div>
                </div>
              </p-tabPanel>
            </p-tabView>

            <div class="p-fluid grid formgrid">
              <!--
              <div class="field col-12 md:col-6">
                <label for="print-batch-delivery-way" class="required">A ser distribuído</label>
                <p-selectButton id="print-batch-delivery-way" [options]="deliveryWayOptions"
                                [(ngModel)]="printBatch.deliveryWay" optionLabel="label" optionValue="value"
                                [disabled]="isLoading" (onChange)="onChangeDeliveryWay($event)"></p-selectButton>
                <small class="cursor-default">Qual a forma de distribuição da impressão? Classificação ou Prefixo?</small>
              </div>
              -->

              <!--
              <div class="col-12 md:col-12" *ngIf="printBatch.deliveryWay === 'C'">
                <p-table [value]="printBatch.distributions" styleClass="p-datatable-striped"
                         [tableStyle]="{ 'min-width': '50rem' }">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="cursor-default">Classificação</th>
                      <th class="cursor-default">Quantidade</th>
                      <th class="cursor-default">Dependências</th>
                      <th class="cursor-default">Total</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-distribution>
                    <tr>
                      <td class="cursor-default">{{ distribution.detail.label }}</td>
                      <td style="max-width: 210px">
                        <p-inputNumber inputId="print-batch-quantity" [(ngModel)]="distribution.quantity"
                                       [showButtons]="true" buttonLayout="horizontal" [allowEmpty]="false"
                                       [min]="0" spinnerMode="horizontal"
                                       [disabled]="isLoading" incrementButtonIcon="fa-solid fa-plus"
                                       decrementButtonIcon="fa-solid fa-minus" class="w-8rem"></p-inputNumber>
                      </td>
                      <td class="cursor-default text-right">{{ distribution.detail.branches | number:'1.0-0' }}</td>
                      <td
                        class="cursor-default text-right">{{ (distribution.quantity * distribution.detail.branches) | number:'1.0-0' }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>

              <div class="col-12 md:col-12" *ngIf="printBatch.deliveryWay === 'P'">
                <p-table [value]="printBatch.distributions" styleClass="p-datatable-striped mb-3"
                         [tableStyle]="{ 'min-width': '50rem' }" *ngIf="printBatch.distributions.length > 0">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="cursor-default">Prefixo</th>
                      <th class="cursor-default">Quantidade</th>
                      <th class="cursor-default">Ação</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-distribution let-i="rowIndex">
                    <tr>
                      <td class="cursor-default">{{ distribution.detail.label }}</td>
                      <td style="max-width: 210px">
                        <p-inputNumber inputId="print-batch-quantity" [(ngModel)]="distribution.quantity"
                                       [showButtons]="true" buttonLayout="horizontal" [allowEmpty]="false"
                                       [min]="0" spinnerMode="horizontal" [disabled]="isLoading"
                                       incrementButtonIcon="fa-solid fa-plus" decrementButtonIcon="fa-solid fa-minus"
                                       class="w-8rem"></p-inputNumber>
                      </td>
                      <td>
                        <p-button icon="fa-solid fa-times" styleClass="p-button-rounded p-button-danger p-button-text"
                                  pTooltip="Remover" tooltipPosition="left"
                                  (onClick)="removePrintBatchDistribution($event, distribution, i)"></p-button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <div class="p-fluid grid formgrid">
                  <div class="field col-12 md:col-12">
                    <label for="print-batch-restricted-prefix" [class.required]="printBatch.distributions.length === 0">Prefixo</label>
                    <p-autoComplete id="print-batch-restricted-prefix" [(ngModel)]="printBatchRestrictedPrefix"
                                    [suggestions]="printBatchRestrictedPrefixOptions"
                                    (completeMethod)="filterPrintBatchRestrictedPrefix($event)" field="label"
                                    [disabled]="isLoading"
                                    (onSelect)="addRestrictedPrefixOnPrintBatch($event)"></p-autoComplete>
                    <small class="cursor-default">Digite o início do nome e selecione uma opção. Pode usar o teclado, é
                      mais fácil. Para escolher pressione a tecla Enter.</small>
                  </div>
                </div>
              </div>
              -->
            </div>
          </p-tabPanel>
          <!-- 3. TAB BATCH PRINTING AND DISTRIBUTION: End -->
        </p-tabView>
      </div>
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px' }"
        *ngIf="isLoading"
      ></p-progressBar>
    </div>

    <div class="flex justify-content-between mt-2">
      <div></div>
      <div>
        <button
          pButton
          type="button"
          label="Cancelar"
          icon="fa-solid fa-times"
          class="inline-flex p-button-danger"
          (click)="cancel()"
          [disabled]="isLoading"
        ></button>
        <button
          pButton
          type="button"
          label="Salvar rascunho"
          icon="fa-solid fa-triangle-exclamation"
          class="ml-2 inline-flex p-button-warning"
          (click)="confirm(true)"
          [disabled]="isLoading"
          *ngIf="id === null"
        ></button>
        <button
          pButton
          type="button"
          label="Salvar"
          icon="fa-solid fa-check"
          class="ml-2 inline-flex"
          (click)="confirm()"
          [disabled]="isLoading"
        ></button>
      </div>
    </div>
  </ng-container>

  <!-- COMMUNICATION DIALOG -->
  <p-dialog
    header="Adicionar ação de comunicação"
    [(visible)]="addCommunicationVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
  >
    <div class="p-fluid grid formgrid">
      <div class="field col-12 md:col-12">
        <label for="communication-name" class="required">Nome</label>
        <input
          id="communication-name"
          #communicationNameInput
          type="text"
          pInputText
          maxlength="300"
          [(ngModel)]="communicationName"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="communicationNameInput"
          [value]="communicationName"
        ></app-characters-remaining>
      </div>

      <div class="field col-12 md:col-12">
        <p-fileUpload
          id="communication-image"
          #communicationImageInput
          name="image[]"
          mode="basic"
          [chooseLabel]="communicationFileUploadChooseLabel"
          accept=".jpg,.png,.gif,.bmp, .jpeg"
          [customUpload]="true"
          (uploadHandler)="onCommunicationFileUpload($event)"
          [auto]="true"
        ></p-fileUpload>
        <small class="cursor-default"
          >Formatos aceitos: <strong>jpg, png, gif, bmp</strong></small
        >
      </div>

      <div class="col-12 md:col-12" *ngIf="canCropCommunicationImage">
        <app-cropper
          #showComunicationCropper
          [cropperOptions]="copperConfig"
          [imageUrl]="communicationImageBase64"
        ></app-cropper>
        <div class="field col-12 md:col-12">
          <div class="flex justify-content-between mt-2">
            <div class="flex">
              <button
                pButton
                type="button"
                pTooltip="Limpar as mudanças"
                icon="fa-solid fa-refresh"
                class="p-button-rounded p-button-text inline-flex"
                (click)="onChangeComunicationCropperReset($event)"
                [disabled]="isLoading"
              ></button>
              <button
                pButton
                type="button"
                pTooltip="Diminuir o zoom"
                icon="fa-solid fa-magnifying-glass-minus"
                class="p-button-rounded p-button-text ml-2 inline-flex"
                (click)="onChangeComunicationCropperDecreaseZoom($event)"
                [disabled]="isLoading"
              ></button>
              <button
                pButton
                type="button"
                pTooltip="Aumentar o zoom"
                icon="fa-solid fa-magnifying-glass-plus"
                class="p-button-rounded p-button-text ml-2 inline-flex"
                (click)="onChangeComunicationCropperIncreaseZoom($event)"
                [disabled]="isLoading"
              ></button>
            </div>
            <div class="flex">
              <button
                pButton
                type="button"
                label="Cancelar"
                icon="fa-solid fa-times"
                class="inline-flex p-button-danger"
                (click)="cancelAddCommunication()"
                [disabled]="isLoading"
              ></button>

              <button
                pButton
                type="button"
                label="Salvar"
                icon="fa-solid fa-check"
                class="ml-2 inline-flex"
                (click)="addCommunication()"
                [disabled]="isLoading"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <small class="text-black-alpha-60 mt-1 cursor-default block"
        >* Para melhor enquadramento, utilizar imagem no formato 1:1,
        quadrada.</small
      >
    </div>

    <!-- <div class="col-12 md:col-12" *ngIf="canCropCommunicationImage">
        <div class="grid">
          <div class="col-12 md:col-8 img-cropper">
            <span class="cursor-default filename-limit">{{ communicationFileName }}</span>
            <image-cropper
              class="mt-2"
              [imageBase64]="communicationImageBase64"
              [maintainAspectRatio]="true"
              [aspectRatio]="4 / 4"
              format="png"
              [resizeToWidth]="480"
              [resizeToHeight]="480"
              (imageCropped)="onCommunicationImageCropped($event)"
              (loadImageFailed)="onLoadImageToCropFailed()"
            ></image-cropper>
          </div>
          <div class="col-12 md:col-4 cropped-image">
            <span class="cursor-default">Pré-visualização</span>
            <img [src]="croppedCommunicationImage"/>
          </div>
        </div>
      </div>
      <small class="text-black-alpha-60 mt-1 cursor-default block">* Para melhor
        enquadramento, utilizar imagem no formato 1:1, quadrada.</small>
      <div class="field col-12 md:col-12">
        <div class="flex justify-content-between mt-2">
          <div></div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelAddCommunication()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Salvar"
              icon="fa-solid fa-check"
              class="ml-2 inline-flex"
              (click)="addCommunication()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>-->
  </p-dialog>
  <!-- // COMUNICATION DIALOG -->

  <!-- ANSWERABLE DIALOG -->
  <p-dialog
    header="Adicionar área demandante"
    [(visible)]="addAnswerableVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
  >
    <div class="p-fluid grid formgrid">
      <div class="field col-12 md:col-9">
        <label for="answerable-shortname">Nome reduzido</label>
        <input
          id="answerable-shortname"
          #answerableShortnameInput
          type="text"
          pInputText
          maxlength="20"
          [(ngModel)]="answerableShortname"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="answerableShortnameInput"
          [value]="answerableShortname"
        >
        </app-characters-remaining>
      </div>

      <div class="field col-12 md:col-3">
        <label for="answerable-prefix">Prefixo</label>
        <input
          id="answerable-prefix"
          type="text"
          pInputText
          maxlength="4"
          [(ngModel)]="answerablePrefix"
          [disabled]="isLoading"
        />
      </div>

      <div class="field col-12 md:col-12">
        <label for="answerable-name" class="required">Nome completo</label>
        <input
          id="answerable-name"
          #answerableNameInput
          type="text"
          pInputText
          maxlength="200"
          [(ngModel)]="answerableName"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="answerableNameInput"
          [value]="answerableName"
        ></app-characters-remaining>
      </div>

      <div class="field col-12 md:col-12">
        <label for="answerable-email">E-mail</label>
        <input
          id="answerable-email"
          #answerableEmailInput
          type="text"
          pInputText
          maxlength="300"
          [(ngModel)]="answerableEmail"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="answerableEmailInput"
          [value]="answerableEmail"
        ></app-characters-remaining>
      </div>

      <div class="field col-12 md:col-12">
        <div class="flex justify-content-between mt-2">
          <div></div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelAddAnswerable()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Salvar"
              icon="fa-solid fa-check"
              class="ml-2 inline-flex"
              (click)="addAnswerable()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <!-- // ANSWERABLE DIALOG -->

  <!-- PUBLICITY AGENCY DIALOG -->
  <p-dialog
    header="Adicionar criador"
    [(visible)]="addPublicityAgencyVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
  >
    <div class="p-fluid grid formgrid">
      <div class="field col-12 md:col-12">
        <label for="publicity-agency-name" class="required">Nome</label>
        <input
          id="publicity-agency-name"
          #publicityAgencyNameInput
          type="text"
          pInputText
          maxlength="200"
          [(ngModel)]="publicityAgencyName"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="publicityAgencyNameInput"
          [value]="publicityAgencyName"
        ></app-characters-remaining>
      </div>
      <div class="field col-12 md:col-12">
        <div class="flex justify-content-between mt-2">
          <div></div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelAddPublicityAgency()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Salvar"
              icon="fa-solid fa-check"
              class="ml-2 inline-flex"
              (click)="addPublicityAgency()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <!-- // ANSWERABLE DIALOG -->

  <!-- SUPPLIER DIALOG -->
  <p-dialog
    header="Adicionar fornecedor"
    [(visible)]="addSupplierVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
  >
    <div class="p-fluid grid formgrid">
      <div class="field col-12 md:col-8">
        <label for="supplier-name" class="required">Nome</label>
        <input
          id="supplier-name"
          #supplierNameInput
          type="text"
          pInputText
          maxlength="200"
          [(ngModel)]="supplierName"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="supplierNameInput"
          [value]="supplierName"
        ></app-characters-remaining>
      </div>
      <div class="field col-12 md:col-4">
        <label for="supplier-federalcode" class="required">CNPJ</label>
        <input
          id="supplier-federalcode"
          #supplierFederalCodeInput
          type="text"
          pInputText
          maxlength="14"
          [(ngModel)]="supplierFederalCode"
          [disabled]="isLoading"
        />
        <app-characters-remaining
          [field]="supplierFederalCodeInput"
          [value]="supplierFederalCode"
        ></app-characters-remaining>
      </div>
      <div class="field col-12 md:col-12">
        <div class="flex justify-content-between mt-2">
          <div></div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelAddSupplier()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Salvar"
              icon="fa-solid fa-check"
              class="ml-2 inline-flex"
              (click)="addSupplier()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <!-- // SUPPLIER DIALOG -->

  <!-- SHOWCASE ITEM DIALOG -->
  <p-dialog
    header="Adicionar imagem ao mostruário"
    [(visible)]="addShowcaseItemVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'center'"
    [class.opacity-50]="isLoading"
  >
    <div class="p-fluid grid formgrid mt-2">
      <span class="small cursor-default filename-limit mb-2">{{
        showcaseItemFileName
      }}</span
      ><br />

      <app-cropper
        #showcaseItemCropper
        [cropperOptions]="copperConfig"
        [imageUrl]="showcaseItemBase64"
      ></app-cropper>

      <small class="text-black-alpha-60 mt-1 cursor-default block"
        >* Para melhor enquadramento, utilizar imagem no formato 1:1,
        quadrada.</small
      >
      <div class="field col-12 md:col-12 p-0">
        <div class="flex justify-content-between mt-2">
          <div class="flex">
            <button
              pButton
              type="button"
              pTooltip="Limpar as mudanças"
              icon="fa-solid fa-refresh"
              class="p-button-rounded p-button-text inline-flex"
              (click)="onChangeShowcaseItemCropperReset($event)"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              pTooltip="Diminuir o zoom"
              icon="fa-solid fa-magnifying-glass-minus"
              class="p-button-rounded p-button-text ml-2 inline-flex"
              (click)="onChangeShowcaseItemCropperDecreaseZoom($event)"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              pTooltip="Aumentar o zoom"
              icon="fa-solid fa-magnifying-glass-plus"
              class="p-button-rounded p-button-text ml-2 inline-flex"
              (click)="onChangeShowcaseItemCropperIncreaseZoom($event)"
              [disabled]="isLoading"
            ></button>
          </div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelAddShowcaseItem()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Salvar"
              icon="fa-solid fa-check"
              class="ml-2 inline-flex"
              (click)="addShowcaseItem()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <!-- // SHOWCASE ITEM DIALOG -->

  <!-- CUSTOM CARD DIALOG -->
  <p-dialog
    header="Selecionar cartão personalizável"
    [(visible)]="selectCustomCardVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
  >
    <div class="p-fluid grid formgrid mt-2">
      <div class="col-12 md:col-12">
        <div class="grid">
          <div class="col-12 md:col-8 img-cropper">
            <span class="cursor-default filename-limit">{{
              customCardFileName
            }}</span>
            <image-cropper
              class="mt-2"
              [imageBase64]="customCardBase64"
              [maintainAspectRatio]="true"
              [aspectRatio]="4 / 4"
              format="png"
              [resizeToWidth]="496"
              [resizeToHeight]="496"
              (imageCropped)="onCustomCardCropped($event)"
              (loadImageFailed)="onLoadImageToCropFailed()"
            ></image-cropper>
          </div>
          <div class="col-12 md:col-4 cropped-image">
            <span class="cursor-default">Pré-visualização</span>
            <img [src]="croppedCustomCard" />
          </div>
        </div>
      </div>

      <div class="field col-12 md:col-12">
        <div class="flex justify-content-between mt-2">
          <div></div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelSelectCustomCard()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Avançar"
              icon="fa-solid fa-arrow-right"
              class="ml-2 inline-flex"
              (click)="selectCustomCard()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <p-dialog
    header="Personalizar cartão"
    [(visible)]="customizeItemVisibility"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
  >
    <div class="p-fluid grid formgrid mt-2">
      <div class="col-12 md:col-12">
        <div class="grid" *ngIf="customCard">
          <div class="col-12 md:col-7">
            <canvas
              id="custom-card-canvas"
              width="496"
              height="496"
              class="border-round custom-card-canvas"
              (mousedown)="onCustomCardCanvasMouseDown($event)"
              (mouseup)="onCustomCardCanvasMouseUp($event)"
              (mousemove)="onCustomCardCanvasMouseMove($event)"
              [class.hidden]="customCardPreview"
            ></canvas>
            <div class="custom-card-preview">
              <img
                [src]="customCard.content!"
                width="498"
                height="498"
                alt=""
                class="border-round custom-card-preview-base"
                [class.hidden]="!customCardPreview"
              />
              <img
                [src]="customCard.preview!"
                width="498"
                height="498"
                alt=""
                class="border-round custom-card-preview-generated"
                [class.hidden]="!customCardPreview"
              />
            </div>

            <div
              id="custom-card-tip"
              style="
                width: 300px;
                display: block;
                position: relative;
                left: 99px;
                top: -249px;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 4px;
                padding: 0.5rem;
                text-align: center;
                font-weight: bold;
              "
            >
              Clique, segure e arraste a linha para definir a posição do texto
            </div>
          </div>
          <div class="col-12 md:col-5">
            <div class="p-fluid grid formgrid">
              <!--
              <div class="field col-12 md:col-12">
                <label for="custom-card-label" class="required">Rótulo</label>
                <input id="custom-card-label" #customCardLabelInput type="text" pInputText [(ngModel)]="customCardLabel"
                       maxlength="50" [disabled]="isLoading" (ngModelChange)="onCustomCardChangeProperties($event)"/>
                <app-characters-remaining [field]="customCardLabelInput"
                                          [value]="customCardLabel"></app-characters-remaining>
              </div>
              -->
              <div class="field col-12 md:col-12 mt-3">
                <label for="custom-card-text-example" class="required"
                  >Texto de exemplo</label
                >
                <input
                  id="custom-card-text-example"
                  #customCardTextExampleInput
                  type="text"
                  pInputText
                  [(ngModel)]="customCardTextExample"
                  [maxlength]="customCardMaxLength"
                  [disabled]="isLoading"
                  (ngModelChange)="onCustomCardChangeProperties($event)"
                />
              </div>
              <div class="field col-12 md:col-6">
                <label for="custom-card-maxlength" class="required"
                  >Máx. de Caracteres</label
                >
                <p-inputNumber
                  inputId="custom-card-maxlength"
                  [(ngModel)]="customCardMaxLength"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  [allowEmpty]="false"
                  [showButtons]="true"
                  [min]="0"
                  [max]="100"
                  spinnerMode="horizontal"
                  [disabled]="isLoading"
                  (ngModelChange)="onCustomCardChangeProperties($event)"
                ></p-inputNumber>
              </div>
              <div class="field col-12 md:col-6">
                <label for="custom-card-fontsize" class="required"
                  >Tamanho da Fonte</label
                >
                <p-inputNumber
                  inputId="custom-card-fontsize"
                  [(ngModel)]="customCardFontSize"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  [allowEmpty]="false"
                  [showButtons]="true"
                  [min]="0"
                  [max]="100"
                  spinnerMode="horizontal"
                  [disabled]="isLoading"
                  (ngModelChange)="onCustomCardChangeProperties($event)"
                ></p-inputNumber>
              </div>
              <div class="field col-12 md:col-4">
                <label for="custom-card-label">Estilo</label>
                <div class="flex justify-content-start">
                  <p-selectButton
                    [options]="customCardTextStyleOptions"
                    [(ngModel)]="customCardTextStyles"
                    [multiple]="true"
                    optionLabel="icon"
                    [disabled]="isLoading"
                    (ngModelChange)="onCustomCardChangeProperties($event)"
                  >
                    <ng-template let-item>
                      <i
                        [class]="item.icon"
                        [pTooltip]="item.label"
                        tooltipPosition="top"
                      ></i>
                    </ng-template>
                  </p-selectButton>
                </div>
              </div>
              <div class="field col-12 md:col-7">
                <label for="custom-card-label">Alinhamento</label>
                <div class="flex justify-content-center">
                  <p-selectButton
                    [options]="customCardTextAlignOptions"
                    [(ngModel)]="customCardTextAlign"
                    optionLabel="icon"
                    [disabled]="isLoading"
                    (ngModelChange)="onCustomCardChangeProperties($event)"
                  >
                    <ng-template let-item>
                      <i
                        [class]="item.icon"
                        [pTooltip]="item.label"
                        tooltipPosition="top"
                      ></i>
                    </ng-template>
                  </p-selectButton>
                </div>
              </div>
              <div class="field col-12 md:col-2">
                <label for="custom-card-label">Cor</label>
                <div class="flex justify-content-start">
                  <p-colorPicker
                    [(ngModel)]="customCardFontColor"
                    [disabled]="isLoading"
                    (ngModelChange)="onCustomCardChangeProperties($event)"
                  ></p-colorPicker>
                </div>
              </div>
              <div class="field col-12 md:col-3">
                <label for="custom-card-position">Posição</label>
                <input
                  id="custom-card-position"
                  type="text"
                  pInputText
                  value="{{ customCardPositionX }}, {{ customCardPositionY }}"
                  [disabled]="true"
                  [readonly]="true"
                />
              </div>
              <div class="field col-12 md:col-3">
                <label for="custom-card-width">Largura</label>
                <input
                  id="custom-card-width"
                  type="text"
                  pInputText
                  value="{{ customCardWidth }}"
                  [disabled]="true"
                  [readonly]="true"
                />
              </div>
              <div class="col-12 md:col-12 flex align-items-center gap-1">
                <p-checkbox
                  [(ngModel)]="customCardPreview"
                  [binary]="true"
                  inputId="custom-card-preview"
                  (ngModelChange)="onCustomCardChangeProperties($event)"
                  [disabled]="isLoading"
                ></p-checkbox>
                <label for="custom-card-preview">Pré-visualizar</label><br />
              </div>
              <div class="col-12 md:col-12">
                <small
                  >Caso esteja marcada a pré-visualização, não é possível mudar
                  a posição de onde o texto deve aparecer.
                  <strong>Desmarque para ativar esse recurso.</strong></small
                >
              </div>
              <!--            <div class="field col-12 md:col-12 mt-3" *ngIf="customCardPreview">-->
              <!--              <label for="custom-card-text-example" class="required">Texto de exemplo</label>-->
              <!--              <input id="custom-card-text-example" #customCardTextExampleInput type="text" pInputText-->
              <!--                     [(ngModel)]="customCardTextExample" [maxlength]="customCardMaxLength" [disabled]="isLoading"-->
              <!--                     (ngModelChange)="onCustomCardChangeProperties($event)"/>-->
              <!--              &lt;!&ndash;-->
              <!--              <app-characters-remaining [field]="customCardTextExampleInput" [value]="customCardTextExample"-->
              <!--                                        [maxlength]="customCardMaxLength"></app-characters-remaining>-->
              <!--              &ndash;&gt;-->
              <!--            </div>-->
            </div>
          </div>
        </div>
      </div>

      <div class="field col-12 md:col-12">
        <div class="flex justify-content-between mt-2">
          <div></div>
          <div class="flex">
            <button
              pButton
              type="button"
              label="Cancelar"
              icon="fa-solid fa-times"
              class="inline-flex p-button-danger"
              (click)="cancelCustomizeItem()"
              [disabled]="isLoading"
            ></button>
            <button
              pButton
              type="button"
              label="Salvar"
              icon="fa-solid fa-check"
              class="ml-2 inline-flex"
              (click)="saveItemCustomization()"
              [disabled]="isLoading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <!-- // CUSTOM CARD DIALOG -->
</div>
